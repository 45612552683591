import React, { useState,useEffect } from 'react';
import {isMobile} from 'react-device-detect';
import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Modal,
  Button,
  TermsOfService,
} from '../../components';
import { TopbarContainer } from '../../containers';
import InstaConnectForm from './InstaConnectForm';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import facebookImage from '../../assets/Facebook-1200x630.jpg';
import SectionFeaturedListing from './SectionFeaturedListing/SectionFeaturedListing';
import twitterImage from '../../assets/Twitter-600x314.jpg';
import css from './LandingPage.module.css';
import testImage from '../LandingPage/SectionFeaturedListing/test.jpg';
import image1 from './testImage/1.png';
import image2 from './testImage/2.png';
import image3 from './testImage/3.png';
import image4 from './testImage/4.png';
import image5 from './testImage/5.png';
import image6 from './testImage/6.png';
import NYTBadge from '../../../src/assets/NYT Badge.png'
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import SignupModal from '../../components/Topbar/SignupModal';
export const LandingPageComponent = props => {
  
  const imageRef = React.useRef(null);
  const heroRef = React.useRef(null)
  const topbarRef = React.useRef(null)


  const [hasMounted, setHasMounted] = useState(false);

  React.useEffect(() => {
    if (imageRef.current) {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      

      const topbarHeight = topbarRef.current ? topbarRef.current.offsetHeight : 0;
      const heroHeight = heroRef.current ? heroRef.current.offsetHeight : 0;
      const imageHeight = imageRef.current ? imageRef.current.offsetHeight: 0;

      // Calculate the space between topbar and hero.
      const space = (heroHeight - topbarHeight) / 2;

      // Calculate the top position by adding the height of the topbar to half of the remaining space
      // after subtracting the height of the image (this will center the image between the two components).
      const topPosition = topbarHeight + (space - imageHeight / 2);


      // Adjust the position based on screen size
      if(isMobile){
        imageRef.current.style.left = width / 40 + 'px';
        imageRef.current.style.top = height / 8 + 'px';
      }else{
        imageRef.current.style.left = width / 35 + 'px';
        imageRef.current.style.top = topPosition / 4 + 'px';
      }
    }
  }, [hasMounted]);


  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    products,
    productsLoading,
    productsError,
  } = props;
  const codeMaybe = new URLSearchParams(location.search).get('code');

  if (codeMaybe) return <InstaConnectForm code={codeMaybe} />;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const test = [image1, image2, image3, image4, image5, image6, testImage];
  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer ref={topbarRef} isLandingPage={true} />
          <a href="https://www.nytimes.com/2023/08/28/style/loyalty-test-infidelity-cheating.html" target="_blank" rel="noopener noreferrer">
            <img ref={imageRef} src={NYTBadge} alt="Description of your image" className={css.yourImageClassName} />
          </a>
        </LayoutWrapperTopbar>



        <LayoutWrapperMain>
          <div ref={heroRef} className={css.heroContainer}>
  

            <SectionHero
              className={css.hero}
              history={history}
              location={location}
              products={products}
            />


          </div>

          <div className={css.featuredListings}>
            <SectionFeaturedListing
              loading={productsLoading}
              error={productsError}
              products={products}
            />
          </div>
          {/* 
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks
                  currentUserListing={currentUserListing}
                  currentUserListingFetched={currentUserListingFetched}
                />
              </div>
            </li>
          </ul> */}
          {location?.state?.showModal ? <SignupModal /> : null}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,
};

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUserListing, currentUserListingFetched } = state.user;
  const { productIds = [], productsLoading, productsError } = state.LandingPage;
  const products = productIds?.map(t => {
    const ref = { id: t, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings?.length === 1 ? listings[0] : null;
  });

  // console.log('after:', productIds); // Added console.log
  // console.log('Products:', products); // Added console.log

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUserListingFetched,
    products,
    productsLoading,
    productsError,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
