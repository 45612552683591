import React from 'react';
import css from './SectionFeaturedListing.module.css';
import ProductCard from './ProductCard';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';
import { IconSpinner, NamedLink } from '../../../components';
import classNames from 'classnames';

function SectionFeaturedListing({ loading, error, products, intl }) {
  if (error) {
    return (
      <div>
        <span className={css.error}>
          {intl.formatMessage({ id: 'LandingPage.SectionFeaturedListingserror' })}
        </span>
      </div>
    );
  }

  if (loading) {
    return (
      <div>
        <p>
          <IconSpinner style={{ style: { height: '50px', width: '50px' } }} />
        </p>
      </div>
    );
  }
  return (
    <>
      <div className={css.container}>
        {(products || []).map((p, index) => (
          <ProductCard
            id={p?.id?.uuid}
            key={p?.id?.uuid}
            metadata={p?.attributes?.metadata}
            title={p?.attributes?.title}
            description={p?.attributes?.description}
            publicData={p?.attributes?.publicData}
            images={p?.images}
            createdAt={p?.attributes?.createdAt}
            intl={intl}
            geolocation={p?.attributes?.geolocation}
            price={p?.attributes?.price}
            authorName={p?.author?.attributes?.profile?.displayName}
            customImage={p}
            profileMetadata={p?.author?.attributes?.profile?.metadata}
          />
        ))}
      </div>
      {products?.length ? (
        <NamedLink name="SearchPage" className={css.heroButton}>
          <FormattedMessage id="SectionHero.browseButtonViewAll" />
        </NamedLink>
      ) : null}
    </>
    // <div className={classNames(css.productsWrapper)}>
    //   {/* {products?.length > 0 ? (
    //     <h3 className={`text-left text-3xl mt-6 ${css.allColumns}`}>
    //       {intl.formatMessage({ id: 'LandingPage.featuredListingTitle' })}
    //     </h3>
    //   ) : null} */}

    // </div>
  );
}

export default injectIntl(SectionFeaturedListing);
