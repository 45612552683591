import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TopbarContainer } from '..';

import {
  Footer,
  IconSpinner,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedRedirect,
  Page,
} from '../../components';
import { authenticateInstagram } from '../../util/api';
import { ensureCurrentUser } from '../../util/data';
import css from './InstaConnectForm.module.css';

function InstaConnectForm({ code, redirectToMaybe }) {
  const [error, setError] = useState(null);
  const { currentUser: user, currentUserLoading } = useSelector(state => state.user);

  const currentUser = ensureCurrentUser(user);

  const history = useHistory();
  console.log({ code });
  useEffect(() => {
    if (code) {
      setError(null);
      authenticateInstagram({ code })
        .then(res => {
          console.log({ res });
          const listingID = res.listingID;
          const listingTitle = res.listingTitle;
          const listingState = res.listingState;

          const url =
            '/l/' +
            String(listingTitle)
              ?.toLowerCase()
              ?.replaceAll(' ', '-') +
            '/' +
            listingID +
            `/${listingState == 'published' ? 'edit' : 'draft'}/socials`;
          //   res.listindID
          if (listingID && listingTitle)
            history.replace({
              pathname: url,
              state: {
                success: true,
              },
            });
          else {
            history.replace({
              pathname: '/',
            });
          }
        })
        .catch(err => {
          const message = err?.response?.data?.message ?? err?.message;
          setError(message);
          const listingID = err?.listingID;
          const listingTitle = err?.listingTitle;
          const url =
            '/l/' +
            String(listingTitle)
              ?.toLowerCase()
              ?.replaceAll(' ', '-') +
            '/' +
            listingID +
            '/edit/socials';
          //   res.listindID
          if (listingID && listingTitle)
            history.replace({
              pathname: url + (message ? '?instagramErr=' + message : ''),
              state: {
                success: false,
                error: message,
              },
            });
          else {
            history.replace({
              pathname: '/',
            });
          }
        });
    }
  }, []);

  if (!currentUser && !currentUserLoading) {
    return <NamedRedirect name="LandingPage" />;
  }

  return (
    <Page contentType="website">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.main}>
            <IconSpinner className={css.spinner} />
            <p className={css.content}>Please wait while we're connecting to to your instagram.</p>

            {error && <p className={css.error}>{error}</p>}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

export default InstaConnectForm;
